'use strict';

const _ = require('lodash');
const workerUtils = require('./workerUtils');
const {bi, ACTION_NAMES} = require('../utils/loggingUtils');
const scriptsValidator = require('./scriptsValidator');
const events = require('../events');
const { WIX_CODE_VIEWER_APP} = require('../constants/store');

function handleLoadUserCode({wixCode, workerId} = {}, {store}) {
  scriptsValidator.validate(wixCode, ['id', 'url', 'scriptName', 'displayName']);

  const routerData = _.get(wixCode, ['0', 'routerData']);
  const wixCodeViewerApp = store.getValue(WIX_CODE_VIEWER_APP);

  if (!_.isEmpty(wixCode) && wixCodeViewerApp) {
    wixCodeViewerApp.fetchUserCode(wixCode, workerUtils.importScriptsAsAmdModule.bind(self, self, 'wixCode'), {routerData});

    bi.report(events.USER_CODE_LOADED, {
      name: ACTION_NAMES.USER_CODE_LOADED,
      worker_id: workerId
    });
  }
}

module.exports = {
  handleLoadUserCode
};
