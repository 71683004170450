'use strict';

const createBootstrapHandler = require('./handlers/createBootstrapHandler');
const createLoadHandler = require('./handlers/createLoadHandler');
const createInitHandler = require('./handlers/createInitHandler');
const createStartHandler = require('./handlers/createStartHandler');

module.exports = {
  create(deps) {
    return {
      bootstrap: createBootstrapHandler(deps),
      load: createLoadHandler(deps),
      init: createInitHandler(deps),
      start: createStartHandler(deps)
    };
  }
};
