'use strict';

const _ = require('lodash');
const {
  BI_LOGGER_FACTORY,
  FEDOPS_LOGGERS,
  BI_LOGGERS,
  WIXCODE_BI_LOGGERS,
  SAMPLED_BI_LOGGERS,
  MUTE_BI,
  IS_DEBUG,
  COIN,
  BI_SAMPLE_BY_REQUEST_ID,
  BI_SESSION_DATA,
  IS_PREVIEW,
  PAGE_LOAD_START,
  NETWORK_PAGE_LOAD_START,
  PAGE_NUMBER
} = require('../constants/logging/store');
const DEFAULT_SAMPLE_RATIO = 10;

function initLoggers(initFunc, path, muteFunc) {
  const biLoggers = initFunc(this.getValue(BI_SESSION_DATA), muteFunc.bind(this), this._messageService.sendMessage);
  return this.setValue(path, biLoggers);
}

function isSiteInSampleRatio() {
  const {sampleRatioState, viewerSessionId, random, coin, requestId} = this.getValue(BI_SESSION_DATA);
  if (sampleRatioState === 'none' || this.getValue(IS_DEBUG) && sampleRatioState !== 'force') {
    return true;
  }

  if (!viewerSessionId) {
    return Math.floor(random * DEFAULT_SAMPLE_RATIO) === 0;
  }

  if (_.isUndefined(coin)) {
    this.setValue(COIN, parseInt(viewerSessionId, 16));
  }

  let res = this.getValue(COIN) % DEFAULT_SAMPLE_RATIO === 0;
  if (!res && _.isString(requestId) && this.getValue(BI_SAMPLE_BY_REQUEST_ID)) {
    const coinFromRequestId = parseInt(requestId, 10);
    res = coinFromRequestId % DEFAULT_SAMPLE_RATIO === 0;
  }

  return res;
}

function shouldMute() {
  return Boolean(this.getValue(IS_PREVIEW)) || this.getValue(MUTE_BI);
}

function shouldMuteWixCodeLogger() {
  return this.getValue(MUTE_BI);
}

function shouldSampleAndMute() {
  return !isSiteInSampleRatio.call(this) || shouldMute.call(this);
}

function initBiSessionData() {
  if (this.getValue(BI_SESSION_DATA)) {
    const getLoadingTime = () => Date.now() - this.getValue(PAGE_LOAD_START);

    const getTotalLoadingTime = () => {
      if (this.getValue(PAGE_NUMBER) !== 1 || !_.get(self, 'performance.now')) {
        return Date.now() - this.getValue(PAGE_LOAD_START);
      }

      const workerPageLoadStart = _.get(self, 'performance.timeOrigin', Date.now() - self.performance.now());
      const timeSinceInitialNetworkRequest = workerPageLoadStart - this.getValue(NETWORK_PAGE_LOAD_START);
      return Math.round(performance.now() + timeSinceInitialNetworkRequest);
    };
    this.updateValue(BI_SESSION_DATA, {
      getLoadingTime: getLoadingTime.bind(this),
      getTotalLoadingTime: getTotalLoadingTime.bind(this)
    });
  }
}

module.exports = class LoggingStore {
  constructor(store = {}, messageService) {
    this._store = store;
    this._messageService = messageService;
    initBiSessionData.call(this);
  }

  setValue(path, value) {
    _.set(this._store, path, value);
  }

  getValue(path) {
    return _.get(this._store, path, null);
  }

  updateValue(path, update) {
    const currentValue = _.get(this._store, path);
    return currentValue && _.isObject(currentValue) ? _.assign(currentValue, update) : this.setValue(path, update);
  }

  initBiLoggerFactoryForApp(getBiLoggerFactoryFunc) {
    const muteFunc = () => this.getValue(MUTE_BI);
    initLoggers.call(this, getBiLoggerFactoryFunc, BI_LOGGER_FACTORY, muteFunc);
  }

  initFedOpsLoggers(getFedOpsLoggersFunc) {
    const muteFunc = () => shouldMute.call(this);
    initLoggers.call(this, getFedOpsLoggersFunc, FEDOPS_LOGGERS, muteFunc);
  }

  initBiLoggers(getBiLoggersFunc) {
    const sampleFunc = () => shouldSampleAndMute.call(this);
    initLoggers.call(this, getBiLoggersFunc, SAMPLED_BI_LOGGERS, sampleFunc);

    const muteFunc = () => shouldMute.call(this);
    initLoggers.call(this, getBiLoggersFunc, BI_LOGGERS, muteFunc);

    const wixCodeMuteFunc = () => shouldMuteWixCodeLogger.call(this);
    initLoggers.call(this, getBiLoggersFunc, WIXCODE_BI_LOGGERS, wixCodeMuteFunc);
  }
};
