'use strict';
const _ = require('lodash');

const createQueue = (orderInfo, processMessage) => {
  const unknownMessages = [];

  const processPendingMessages = items => {
    const finished = items.every(info => {
      info.pending.forEach(msg => processMessage(msg));
      info.numOfHandledMessages += info.pending.length;
      info.pending = [];

      info.done = info.done || info.minimumNumOfMessages <= info.numOfHandledMessages;

      return info.done;
    });

    if (finished) {
      unknownMessages.forEach(processMessage);
    }
  };

  const queue = _.map(orderInfo, ({type, minimumNumOfMessages = 1}) => ({type, done: false, minimumNumOfMessages, pending: [], numOfHandledMessages: 0}));

  return {
    updateMinimumNumberOfMessages: (type, minimumNumOfMessages) => {
      const item = _.find(queue, {type});
      item.minimumNumOfMessages = minimumNumOfMessages;
      processPendingMessages(queue);
    },
    handle: ({message, type}) => {
      if (queue.every(item => item.done)) {
        processMessage(message);
        return;
      }
      
      const item = _.find(queue, {type});
      if (item) {
        item.pending.push(message);
        processPendingMessages(queue);  
      } else {
        unknownMessages.push(message);
      }
    }
  };
};

module.exports.createQueue = createQueue;



