'use strict';

module.exports = {
  OPENED_EXPERIMENTS: 'openedExperiments',
  CSRF_TOKEN: 'csrfToken',
  VIEW_MODE: 'sdkParameters.viewMode',
  RENDERING_ENV: 'sdkParameters.renderingEnv',
  SDK_PARAMETERS: 'sdkParameters',
  WIX_CODE_VIEWER_APP: 'wixCodeViewerApp'
};
