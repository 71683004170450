'use strict';

const workerUtils = require('./workerUtils');
const scriptsValidator = require('./scriptsValidator');
const scriptsHandler = require('./scriptHandler');
const {fedops, ACTION_NAMES} = require('../utils/loggingUtils');
const _ = require('lodash');


function loadUserGeneratedApps({applications = []} = {}, appsStore) {
  const {reportAppLoadingPhaseStart, reportAppLoadingPhaseFinish} = fedops.getAppLoadingPhaseReportFunctions({name: ACTION_NAMES.USER_GENERATED_APPS_LOADED});
  const beforeLoad = Date.now();
  reportAppLoadingPhaseStart();

  scriptsValidator.validate(applications, ['id', 'url']);

  workerUtils.importModules(applications, appsStore, scriptsHandler, true);

  reportAppLoadingPhaseFinish({duration: _.now() - beforeLoad});
}

module.exports = {
  loadUserGeneratedApps
};
