'use strict';

const scriptMap = {};
const {addQueryParamToUrl} = require('../utils/urlUtils');
const {bi, ACTION_NAMES} = require('../utils/loggingUtils');
const {PREFETCH_SCRIPT_EVAL_FAILED, IMPORT_SCRIPT_WITHOUT_CACHEKILLER_FAILED} = ACTION_NAMES;
const _ = require('lodash');

function sendBi(error, errorType, url, beforeLoad) {
  return bi.reportPlatformRenderError({
    name: errorType,
    duration: _.now() - beforeLoad,
    details: JSON.stringify({scriptUrl: url}),
    error: error.message
  });
}

function handleScript({url, script}) {
  if (!scriptMap[url]) {
    scriptMap[url] = script;
  }
}

function bytesToString(ab) {
  if (typeof TextDecoder !== 'undefined') {
    try {
      const td = new TextDecoder();
      return td.decode(ab);
    } catch (e) {
      // empty
    }
  }
  try {
    //eslint-disable-next-line no-undef
    return String.fromCharCode.apply(null, new Uint8Array(ab));
  } catch (e) {
    // empty
  }
  return null;
}

function cacheKiller() {
  return Date.now();
}

function importScripts(url, verifyImport) {
  const beforeLoad = Date.now();
  const script = scriptMap[url];
  if (script) {
    delete scriptMap[url];
    const code = bytesToString(script);
    if (code) {
      try {
        //eslint-disable-next-line no-eval
        eval.call(null, `${code}\n//# sourceURL=${url}`);
        return;
      } catch (error) {
        sendBi(error, PREFETCH_SCRIPT_EVAL_FAILED, url, beforeLoad);
      }
    }
  }

  try {
    self.importScripts(url);
  } catch (err) {
    sendBi(err, IMPORT_SCRIPT_WITHOUT_CACHEKILLER_FAILED, url, beforeLoad);
    const urlWithCacheKiller = addQueryParamToUrl(url, `cache-killer=${cacheKiller()}`);
    self.importScripts(urlWithCacheKiller);
    if (_.isFunction(verifyImport) && !verifyImport()) {
      throw new Error(`import ${url} with cache killer failed`);
    }
  }
}

module.exports = {
  handleScript,
  importScripts
};
