'use strict';

const _ = require('lodash');
const LoggingStore = require('../stores/LoggingStore');
const {
  FEDOPS_LOGGERS,
  BI_LOGGERS,
  SAMPLED_BI_LOGGERS,
  WIXCODE_BI_LOGGERS,
  IS_SERVER_SIDE,
  BI_SESSION_DATA,
  PAGE_LOAD_START,
  BI_LOGGER_FACTORY,
  REPORT_TRACE,
  PAGE_ID,
  PAGE_URL,
  FEDOPS_NO_SAMPLING
} = require('../constants/logging/store');

const {
  ACTION_NAMES,
  FEDOPS,
  TRACE_PARAM_KEY_MAP,
  DEFAULT_LOGGER_ENDPOINT
} = require('../constants/logging/constants');

const events = require('../events');
const errors = require('../errors');
let _store = new LoggingStore();

function init(biStoreData, messageService, loggerInitFuncs) {
  _store = new LoggingStore(biStoreData, messageService);

  const {getFedOpsLoggers, getBiLoggers, getBiLoggerFactory} = loggerInitFuncs;
  _store.initFedOpsLoggers(getFedOpsLoggers);
  _store.initBiLoggers(getBiLoggers);
  _store.initBiLoggerFactoryForApp(getBiLoggerFactory);
}

function getLogger(path) {
  const loggers = _store.getValue(path);
  if (!loggers) {
    return loggers;
  }

  const {server, client} = loggers;
  return _store.getValue(IS_SERVER_SIDE) ? server : client;
}

function getBiLogger(isSampled) {
  const path = isSampled || !_store.getValue(FEDOPS_NO_SAMPLING) ? SAMPLED_BI_LOGGERS : BI_LOGGERS;
  return getLogger(path);
}

function getTraceLogger() {
  return getLogger(BI_LOGGERS);
}

function getFedOpsLogger() {
  return getLogger(FEDOPS_LOGGERS);
}

function reportFedops(functionName, args) {
  const fedopsLogger = getLogger(FEDOPS_LOGGERS);
  if (fedopsLogger) {
    const fedopsFunction = fedopsLogger[functionName];
    if (_.isFunction(fedopsFunction)) {
      fedopsFunction.call(fedopsLogger, ...args);
    }
  }
}

function reportAppLoadStarted(params) {
  reportFedops(FEDOPS.APP_LOAD_STARTED, [params]);
}

function reportPlatformLoadStarted() {
  reportAppLoadStarted();
}

function reportAppLoaded(params) {
  reportFedops(FEDOPS.APP_LOADED, [params]);
}

function reportAppLoadingPhaseStart(name, params) {
  reportFedops(FEDOPS.APP_LOADING_PHASE_START, [name, params]);
}

function reportAppLoadingPhaseFinish(name, params, extraParams) {
  reportFedops(FEDOPS.APP_LOADING_PHASE_FINISH, [name, params]);
  const traceParams = _.assign({}, _.assign(params, extraParams), {name});
  trace(traceParams);
}

function getAppLoadingPhaseReportFunctions({name, details, params}) {
  const phaseActionName = getPhaseActionName(name, details);
  return {
    reportAppLoadingPhaseStart: reportAppLoadingPhaseStart.bind(null, phaseActionName, params),
    reportAppLoadingPhaseFinish: reportAppLoadingPhaseFinish.bind(null, phaseActionName, params)
  };
}

function getPhaseActionName(name, details) {
  return details ? `${name}_${details}` : name;
}

function getParamsToReport(params, additionalParams) {
  return _.assign({}, params, additionalParams);
}

function reportBi(reportDef, additionalParams, logger) {
  const {params, endpoint, isSampled} = reportDef;
  const message = getParamsToReport(params, additionalParams);
  const biLogger = logger || getBiLogger(isSampled);
  if (biLogger) {
    biLogger.log(message, {endpoint: endpoint || DEFAULT_LOGGER_ENDPOINT});
  }
}

function report(reportDef, params, isErrorEvent = false) {
  reportBi(reportDef, params);

  if (!isErrorEvent) {
    trace(params);
  }
}

function trace(biParams) {
  if (_store.getValue(REPORT_TRACE)) {
    const timeFromStartMs = _.now() - _store.getValue(PAGE_LOAD_START);
    const defaultParams = {
      appId: 'platform',
      tracePosition: 'after',
      timeFromStartMs,
      timestampMs: new Date().getTime(),
      pageId: _store.getValue(PAGE_ID),
      pageUrl: _store.getValue(PAGE_URL)
    };

    const params = _({})
      .assign(defaultParams, biParams)
      .mapKeys((param, key) => TRACE_PARAM_KEY_MAP[_.camelCase(key)] || key)
      .value();
    reportBi(events.REPORT_TRACE, params, getTraceLogger());
  }
}

function reportPlatformRenderError(biParams) {
  report(errors.PLATFORM_RENDER_ERROR, biParams, true);
}

function reportWixCodeBi(reportDef, params) {
  const logger = getLogger(WIXCODE_BI_LOGGERS);
  reportBi(reportDef, params, logger);
}

function getBiServices() {
  return {
    reporters: {
      reportBI: reportWixCodeBi.bind(this)
    }
  };
}

function getBiSessionData() {
  return _.clone(_store.getValue(BI_SESSION_DATA));
}

function updateBiSessionData(updates) {
  return _store.updateValue(BI_SESSION_DATA, updates);
}

function updateBiStoreData(updates) {
  _.forEach(updates, (value, path) => _store.updateValue(path, value));
}

function getBiLoggerFactoryForApp(appDefaults) {
  return _store.getValue(BI_LOGGER_FACTORY)(appDefaults);
}

module.exports = {
  init,
  getBiLoggerFactoryForApp,
  getFedOpsLoggerFactory: getFedOpsLogger,
  fedops: {
    getAppLoadingPhaseReportFunctions,
    reportAppLoadStarted,
    reportAppLoaded,
    reportPlatformLoadStarted: _.once(reportPlatformLoadStarted)
  },
  bi: {
    report,
    trace,
    reportPlatformRenderError
  },
  getBiServices,
  getBiSessionData,
  updateBiSessionData,
  updateBiStoreData,
  ACTION_NAMES
};
