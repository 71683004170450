'use strict';

const ACTION_NAMES = {
  WORKER_CREATED: 'WORKER_CREATED',
  SCRIPT_LOADED: 'SCRIPT_LOADED',
  INIT_APP_FOR_PAGE: 'INIT_APP_FOR_PAGE',
  CREATE_CONTROLLERS: 'CREATE_CONTROLLERS',
  CONTROLLER_PAGE_READY: 'CONTROLLER_PAGE_READY',
  APP_LOADED: 'APP_LOADED',
  BOOTSTRAP_DONE: 'BOOTSTRAP_DONE',
  USER_CODE_LOADED: 'USER_CODE_LOADED',
  USER_GENERATED_APPS_LOADED: 'USER_GENERATED_APPS_LOADED',
  ALL_APPS_PAGE_READY: 'ALL_APPS_PAGE_READY',
  APP_PAGE_READY: 'APP_PAGE_READY',
  HANDLE_INIT_FAILED: 'HANDLE_INIT_FAILED',
  PAGE_READY_FAILED: 'PAGE_READY_FAILED',
  PREFETCH_SCRIPT_EVAL_FAILED: 'PREFETCH_SCRIPT_EVAL_FAILED',
  IMPORT_SCRIPT_WITHOUT_CACHEKILLER_FAILED: 'IMPORT_SCRIPT_WITHOUT_CACHEKILLER_FAILED',
  CREATE_CONTROLLERS_FAILED: 'CREATE_CONTROLLERS_FAILED',
  INIT_APP_FOR_PAGE_FAILED: 'INIT_APP_FOR_PAGE_FAILED',
  SCRIPT_LOAD_FAILED: 'SCRIPT_LOAD_FAILED',
  LOAD_DONE: 'loadDone'
};

const FEDOPS = {
  APP_LOAD_STARTED: 'appLoadStarted',
  APP_LOADING_PHASE_START: 'appLoadingPhaseStart',
  APP_LOADING_PHASE_FINISH: 'appLoadingPhaseFinish',
  APP_LOADED: 'appLoaded'
};

const TRACE_PARAM_KEY_MAP = {
  appId: 'appName',
  ts: 'timeFromStartMs',
  duration: 'actionDurationMs',
  name: 'actionName'
};

const DEFAULT_LOGGER_ENDPOINT = 'platform-viewer';

module.exports = {
  ACTION_NAMES,
  FEDOPS,
  TRACE_PARAM_KEY_MAP,
  DEFAULT_LOGGER_ENDPOINT
};
