'use strict';
const MessageType = require('../../wixCodeMessageTypes');
const _postMessage = self.postMessage;

class MessageService {
  sendBootstrapMessage() {
    this.sendMessage({
      intent: MessageType.WIX_CODE_SSR,
      type: MessageType.BOOTSTRAP_DONE
    });
  }

  sendWidgetReadyMessage(widgetId) {
    this.sendMessage({
      intent: MessageType.WIX_CODE_INTENT,
      type: MessageType.WIDGET_READY,
      widgetId
    });
  }

  sendWidgetStatusChangedMessage(widgetId, status) {
    this.sendMessage({
      intent: MessageType.WIX_CODE_INTENT,
      type: MessageType.WIDGET_STATUS_CHANGE,
      widgetId,
      status
    });
  }

  sendPerformanceMetricsMessage(workerId, metrics) {
    this.sendMessage({
      intent: MessageType.WIX_CODE_INTENT,
      type: MessageType.PERFORMANCE_METRICS_READY,
      workerId,
      metrics
    });
  }

  sendMessage(message, ports) {
    const args = [message];

    if (typeof WorkerGlobalScope === 'undefined') {
      args.push('*');
    }
    if (ports) {
      args.push(ports);
    }

    return _postMessage.apply(self, args);
  }
}

module.exports = MessageService;
