'use strict';

const _ = require('lodash');
const MessageType = require('../wixCodeMessageTypes');

function _getScriptEntry(widget) {
  const userScriptDetails = widget.appConfig.userScript;

  return {
    id: widget.id,
    url: userScriptDetails.url,
    scriptName: userScriptDetails.scriptName,
    displayName: userScriptDetails.displayName,
    routerData: widget.routerData
  };
}

function getUserCodeUrlsDetails(widgets, rootId) {
  const urls = [];

  const pageWidget = _.find(widgets, widget => widget.id === rootId && widget.type !== 'masterPage');

  const masterPageWidget = _.find(widgets, {id: rootId, type: 'masterPage'});

  if (masterPageWidget) {
    urls.push(_getScriptEntry(masterPageWidget));
  }

  if (pageWidget) {
    urls.push(_getScriptEntry(pageWidget));
  }

  return urls;
}

function sendLogMessageToViewer(postMessage, data) {
  postMessage({
    intent: MessageType.WIX_CODE_INTENT,
    type: MessageType.CONSOLE_MESSAGE,
    data
  });
}

module.exports = {
  getUserCodeUrlsDetails,
  sendLogMessageToViewer
};
